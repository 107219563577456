import React from 'react'
import Demo from '../components/demo/Demo';


const BookADemo = () => {
  return (
    <>
      <Demo />
    </>
  )
}

export default BookADemo