import style from './Curve.module.css'


function Curve() {
    return (
        <>
            <div className={style.curve}>
                <div className={style.white_curve}>
                    
                </div>
            </div>
        </>
    );
}

export default Curve;